import React from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider, merge, Flex, Box, css } from 'theme-ui'
import baseTheme from '@solid-ui-theme'
// helpers
import pageContextProvider from '@helpers/pageContextProvider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
// components
import { FormContextProvider } from '@solid-ui-components/ContentForm'
import { ModalContextProvider } from '@solid-ui-components/Modal'
import { TabsContextProvider } from '@solid-ui-components/Tabs'
// blocks
import Header from '@solid-ui-blocks/Header/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import ScrollButton from '@solid-ui-components/ScrollButton/ScrollButton'

const Layout = ({ children, pageContext = {}, location, theme = {}, allBlockContent }) => {
  const content = normalizeBlockContentNodes(allBlockContent?.nodes) ?? {}
  const isPathCaseStudy = location.pathname.includes('case-studies')

  return (
    <ThemeProvider theme={merge(baseTheme, theme)}>
      <pageContextProvider.Provider value={{ pageContext, location }}>
        <FormContextProvider>
          <ModalContextProvider>
            <TabsContextProvider>
              <Flex variant='layout.layout'>
                <Global styles={css(theme => theme.global)} />
                <Header content={isPathCaseStudy ? content['header-light'] : content['header']} />
                {/* Modals */}
                <ModalWithTabs content={content['contact']} />
                <ModalSimple content={content['consultation']} />
                <Box variant='layout.body'>{children}</Box>
                <ScrollButton showBelow={250} />
                <Footer content={content['footer']} />
              </Flex>
            </TabsContextProvider>
          </ModalContextProvider>
        </FormContextProvider>
      </pageContextProvider.Provider>
    </ThemeProvider>
  )
}

export default Layout
