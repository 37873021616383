import React, { useState, useEffect } from 'react'
import { IconButton } from 'theme-ui'
import { GoChevronUp } from 'react-icons/go'

const styles = {
    scrollButton: {
        zIndex: 2,
        position: 'fixed',
        bottom: '2vh',
        backgroundColor: 'beta',
        color: 'white',
        "&:hover, &.Mui-focusVisible": {
            transition: '0.3s',
            backgroundColor: 'betaLight'
        },
        right: '5%',
    }
}

const ScrollButton = ({ showBelow }) => {
    const [show, setShow] = useState(showBelow ? false : true);

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true);
        } else {
            if (show) setShow(false);
        }
    };

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })


    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: 'smooth' })
    };

    return (
        <div>
            {show &&
                <IconButton onClick={handleClick} sx={styles.scrollButton}>
                    <GoChevronUp size={20} />
                </IconButton>
            }
        </div>
    )
}

export default ScrollButton